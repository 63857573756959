<template>
  <attendEmployee
    :isLoad="isLoad"
    :isOrder="true"
    :messageApi="messageApi"
    @downLoadExcel="fetchData"
    name="الطرود"
  />
</template>

<script>
import attendEmployee from "./report.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoad: false,
      messageApi: "",
    };
  },
  components: {
    attendEmployee,
  },
  computed: {
    ...mapGetters("dataList", ["message", "success_api"]),
  },
  methods: {
    ...mapActions("dataList", ["excelOrder"]),
    async fetchData(value) {
      this.isLoad = true;
      await this.excelOrder(value);
      this.messageApi = this.message;
      this.isLoad = false;
    },
  },
};
</script>
